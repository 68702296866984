import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    items: []
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loading = status
    },
    SET_ITEMS(state, items) {
      state.items = items
    },
    ADD_ITEM(state, item) {
      state.items.push(item)
    },
    REMOVE_ITEM(state, content_id) {
      state.items = state.items.filter(item => item.content_id !== content_id)
    },
    CLEAR_ITEMS(state) {
      state.items = []
    }
  },
  actions: {
    async fetchItems({ commit }) {
      commit("SET_LOADING_STATUS", true)
      let items = await Vue.prototype.$api.values("cart")
      commit("SET_ITEMS", items)
      commit("SET_LOADING_STATUS", false)
    },
    async add({ commit }, content_id) {
      commit("SET_LOADING_STATUS", true)
      let response = await Vue.prototype.$api.post("cart/add", { content_id })
      commit("ADD_ITEM", response.data.item)
      commit("SET_LOADING_STATUS", false)
    },
    async remove({ commit }, content_id) {
      commit("SET_LOADING_STATUS", true)
      let response = await Vue.prototype.$api.post("cart/remove", { content_id })
      if (response.data.deleted) commit("REMOVE_ITEM", content_id)
      commit("SET_LOADING_STATUS", false)
    },
    async clear({ commit }) {
      commit("SET_LOADING_STATUS", true)
      let response = await Vue.prototype.$api.post("cart/clear")
      if (response.data.deleted) commit("CLEAR_ITEMS")
      commit("SET_LOADING_STATUS", false)
    }
  },
  getters: {
    items: state => state.items,
    count: state => (state.items && state.items.length) || 0,
    find: state => id => state.items.find(item => item.content_id == id)
  }
}
