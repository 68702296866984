import Vue from "vue"
import Vuex from "vuex"

import { AuthStoreModule } from "@syntax51/app-kit"
import CartStoreModule from "./CartStoreModule"
import CollectionsStoreModule from "./CollectionsStoreModule"
import CollectionStoreModule from "./CollectionStoreModule"
import ContentStoreModule from "./ContentStoreModule"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthStoreModule(),
    cart: CartStoreModule,
    collections: CollectionsStoreModule,
    collection: CollectionStoreModule,
    content: ContentStoreModule
  }
})
