<template>
  <div v-if="[304, 566].includes(user.id) || user.internal">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  }
}
</script>
