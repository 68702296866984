import { mapGetters } from "vuex"
import Cart from "./Cart"

export default {
  components: {
    Cart
  },
  data: () => ({
    cartOpen: false,
    clickOutsideEvent: false
  }),
  computed: {
    ...mapGetters({
      cartCount: "cart/count",
      user: "auth/user"
    }),
    cartLabel() {
      return `${this.cartCount} ${this.cartCount === 1 ? "item" : "items"}`
    }
  },
  methods: {
    toggleCart() {
      this.cartOpen = !this.cartOpen
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => this.$router.push({ name: "login" }))
    }
  },
  mounted() {
    this.clickOutsideEvent = event => {
      let headerClicked = event.target && this.$el.contains(event.target)
      let cartClicked = event.target && this.$refs.cart && this.$refs.cart.$el.contains(event.target)

      if (this.cartOpen && !headerClicked && !cartClicked) {
        this.cartOpen = false
      }
    }
    document.body.addEventListener("click", this.clickOutsideEvent)
  },
  unbind() {
    document.body.removeEventListener("click", this.clickOutsideEvent)
  }
}
