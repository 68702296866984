<template lang="pug">
  div.app
    BreakpointHelper
    Header
    MobileHeader
    MobileSidebar
    div.app-body
      slot
</template>

<script>
import BreakpointHelper from "@syntax51/app-kit/components/BreakpointHelper"
import Header from "./partials/Header/Header"
import MobileHeader from "./partials/Header/MobileHeader"
import MobileSidebar from "./partials/MobileSidebar"
import { titleTemplate } from "@/config"

export default {
  metaInfo: {
    titleTemplate
  },
  components: {
    BreakpointHelper,
    Header,
    MobileHeader,
    MobileSidebar
  },
  created() {
    this.$store.dispatch("cart/fetchItems")
    this.$store.dispatch("collections/fetchCollections")
  }
}
</script>

<style>
.app {
  @apply relative flex min-h-screen flex-col;

  &-body {
    @apply flex w-full flex-grow flex-col bg-gray-200;
  }

  .page-content {
    @apply p-0;
  }

  .card-title {
    @apply text-lg;
  }

  a.is-grayscale {
    @apply text-gray-500;

    &:hover {
      @apply text-gray-700;
    }
  }

  .button {
    @apply rounded-full;
  }

  p + p {
    @apply mt-6;
  }
}
</style>
