import Vue from "vue"
import store from "@/store"

// Drift Middleware, records user activity (page movement).
export default (to, from, next) => {
  const environment = process.env.NODE_ENV
  const userIsLoggedIn = store.getters["auth/authenticated"]

  if (userIsLoggedIn && environment === "production") Vue.prototype.$drift.page()
  next()
}
