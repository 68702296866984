<template lang="pug">
  div.cart-item
    div.cart-item__icon
      Icon(:icon="content.icon")
    div.cart-item__body
      div.cart-item__header
        p.cart-item__title {{ content.title }}
        a.cart-item__remove(@click="removeThis"): Icon(icon="times")
      div.cart-item__description(v-html="content.synopsis")
</template>

<script>
export default {
  props: {
    content: Object
  },
  methods: {
    removeThis() {
      this.$store.dispatch("cart/remove", this.content.content_id)
    }
  }
}
</script>

<style>
.cart-item {
  &__icon {
    @apply mr-3 w-4 flex-shrink-0 text-primary-default;

    .fa-book-open {
      @apply text-sm;
    }
  }

  &__header {
    @apply flex justify-between;
  }

  &__title {
    @apply mb-1 font-medium text-primary-default;
  }

  &__remove {
    @apply block text-supplement;

    &:hover {
      @apply text-red-500;
    }
  }
}
</style>
