import Vue from "vue"
import { findIndex } from "lodash-es"

export default {
  namespaced: true,
  state: {
    loading: false,
    collection: {}
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loading = status
    },
    SET_COLLECTION(state, collection) {
      state.collection = collection
    },
    ADD_BLOCK(state, block) {
      state.collection.contents.push(block)
    },
    UPDATE_BLOCK(state, block) {
      const blocks = state.collection.contents
      const index = findIndex(blocks, { id: block.id })
      blocks.splice(index, 1, block)
    },
    DELETE_BLOCK(state, id) {
      const blocks = state.collection.contents
      const index = findIndex(blocks, { id: id })
      blocks.splice(index, 1)
    }
  },
  actions: {
    async find({ commit }, id) {
      commit("SET_LOADING_STATUS", true)
      let response = await Vue.prototype.$api.get(`collections/${id}`)
      commit("SET_COLLECTION", response.data.data)
      commit("SET_LOADING_STATUS", false)
    },
    addBlock({ commit }, block) {
      commit("ADD_BLOCK", block)
    },
    updateBlock({ commit }, block) {
      commit("UPDATE_BLOCK", block)
    },
    deleteBlock({ commit }, id) {
      commit("DELETE_BLOCK", id)
    }
  },
  getters: {
    collection: state => state.collection
  }
}
