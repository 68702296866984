<template lang="pug">
  header.header
    div.header-inner
      div.header-main
        Logo
        Navigation.header-navigation.horizontal(icon-prefix="fas")
          NavigationLink(:to="{name: 'dashboard'}", icon="search", v-if="user.hasPermission('search.destination')") Search
          NavigationLink(:to="{name: 'content'}", icon="content", v-if="user.hasPermission('content.private_manage')") Exclusive Content
          NavigationLink(:to="{name: 'collections'}", icon="collection", v-if="user.hasPermission('collections.manage') || user.hasPermission('collections.shared_manage')") Collections
          NavigationLink(:to="{name: 'forms'}", icon="block-form", v-if="user.partner.has_premium_forms && user.hasPermission('advanced.forms') || user.internal") Forms
          NavigationLink(:to="{name: 'analytics'}", icon="analytics", v-if="user.hasPermission('analytics.reporting')") Analytics
      div.header-controls(gap="xxs")
        div.header-controls-item(v-if="user.hasPermission('collections.shared_manage') || user.hasPermission('collections.manage')")
          Button.header-add-to-collection(type="primary", @click="toggleCart", prepend-icon="shopping-bag") {{ cartLabel }}
          Cart(ref="cart", @close="cartOpen = false", v-show="cartOpen")
        div.header-controls-item: Dropdown.header-avatar.flip-menu
          template(v-slot:label): span.header-avatar-content {{ user.initials || '??' }}
          DropdownLink(:to="{name: 'settings.account'}", icon="user-circle") Account Settings
          DropdownHorizontalRule
          DropdownLink(icon="door-open", @click="logout") Logout
</template>

<script>
import HeaderMixin from "./HeaderMixin"

export default {
  mixins: [HeaderMixin]
}
</script>

<style>
.header {
  @apply relative hidden;
  @apply lg:flex;
  height: theme("header.height");

  &-inner {
    @apply fixed left-0 right-0 top-0 z-50 flex w-full items-center justify-between bg-white px-4 shadow;
  }

  &-main {
    @apply flex items-center;
  }

  &-controls {
    @apply relative -mx-2 flex items-center;

    &-item {
      @apply relative px-2;
    }
  }

  .button {
    @apply py-0 normal-case;
    height: 36px;
    line-height: 36px;

    &.header-add-to-collection {
      @apply border-secondary-default bg-secondary-default;

      &:hover {
        @apply border-secondary-hover bg-secondary-hover;
      }
    }
  }

  .dropdown-menu {
    top: 51px;
  }

  .navigation-link {
    height: theme("header.height");
  }

  &-avatar {
    @apply ml-1;

    .dropdown-trigger-icon {
      @apply ml-2;
    }

    &-content {
      @apply flex items-center justify-center rounded-full bg-primary-default text-xs font-semibold text-white transition-colors;
      height: 36px;
      width: 36px;
    }

    &:hover {
      .avatar-content {
        @apply bg-primary-600;
      }
    }
  }
}
</style>
