<template lang="pug">
  div.mobile-sidebar
    div.mobile-sidebar-header(tag="header")
      div: Logo
      div: a.close(@click="close()"): Icon(icon="times")
    div.mobile-sidebar-body(tag="main")
      Navigation(icon-prefix="fas")
        NavigationLink(@click="goToRoute('dashboard')", icon="search", :class="activeClass('dashboard')", v-if="user.hasPermission('search.destination')") Search
        NavigationLink(@click="goToRoute('content')", icon="content", :class="activeClass('content')", v-if="user.hasPermission('content.private_manage')") Exclusive Content
        NavigationLink(@click="goToRoute('collections')", icon="collection", :class="activeClass('collections')", v-if="user.hasPermission('collections.manage') || user.hasPermission('collections.shared_manage')") Collections
        NavigationLink(@click="goToRoute('forms')", icon="block-form", :class="activeClass('forms')", v-if="user.partner.has_premium_forms && user.hasPermission('advanced.forms') || user.internal") Forms
        NavigationLink(@click="goToRoute('analytics')", icon="analytics", :class="activeClass('analytics')", v-if="user.hasPermission('analytics.reporting')") Analytics
    div.mobile-sidebar-footer(tag="footer")
      div.controls
        Navigation
          NavigationLink(@click="goToRoute('settings.account')", icon="cog", :class="activeClass('settings.account')") Account
          NavigationLink(@click="logout()", icon="door-open") Logout
        //- Button.controls-item.controls-item-add-to-collection(type="primary", prepend-icon="search")
        //- Button.controls-item(type="primary", @click="goToRoute('content.new')") NEW CONTENT
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data: () => ({
    currentRouteName: ""
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  created() {
    this.currentRouteName = this.$router.currentRoute.name
  },
  methods: {
    activeClass(routeName) {
      return routeName === this.currentRouteName ? "is-active" : ""
    },
    goToRoute(routeName, params = {}) {
      if (routeName != this.$router.currentRoute.name) {
        this.currentRouteName = routeName
        this.$router.push({ name: routeName, params })
      }

      this.close()
    },
    close() {
      document.body.classList.remove("mobile-sidebar-open")
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => this.$router.push({ name: "login" }))
    }
  }
}
</script>

<style>
.mobile-sidebar {
  @apply fixed bottom-0 right-0 top-0 z-50 flex w-full flex-col justify-between bg-white p-8 transition-all duration-200;
  @apply lg:hidden;
  left: -100%;
  max-width: 375px;

  .navigation-link-wrapper {
    .navigation-link {
      @apply py-3;
    }

    &.is-active a {
      @apply text-primary-default;

      &:before {
        @apply absolute block rounded-full bg-primary-default;
        content: "";
        height: 2px;
        left: -110px;
        margin-top: -1px;
        top: 50%;
        width: 100px;
      }
    }
  }

  &-header {
    @apply flex justify-between;
  }

  &-footer {
    @apply -mb-3;
  }

  .close {
    @apply -mr-3 -mt-3 p-3;
  }
}

body.mobile-sidebar-open {
  @apply overflow-hidden;
  @apply lg:overflow-auto;

  .mobile-sidebar {
    @apply left-0 shadow-lg;
    @apply lg:hidden;
  }
}
</style>
