import store from "store2"
const persist = store.namespace("content")

export default {
  namespaced: true,
  state: {
    template: persist.get("template") || "default"
  },
  mutations: {
    changeTemplate(state, template) {
      state.template = template
      persist.set("template", template)
    }
  },
  actions: {
    changeTemplate({ commit }, template) {
      commit("changeTemplate", template)
    }
  },
  getters: {
    template: state => state.template
  }
}
