<template lang="pug">
  div.cart

    div.cart__body(v-show="view === 'cart'")

      div.cart__empty(v-show="isEmpty")
        div.cart__intro-text Nothing here yet! The #[Button(type="primary", prepend-icon="plus")] adds content to this collection-building cart.
        img.cart__intro-image(src="@/assets/images/cart-intro.jpg")

      div.cart__list(v-show="!isEmpty")
        p.cart__count You have #[strong {{ count }}] pieces of content ready to add to a collection:
        CartItem.cart__list__item(v-for="content in items", :content="content", :key="content.id")

    div.cart__body.cart__body--checkout(v-show="view === 'checkout'")
      Tabs(@change="onCheckoutTab")

        Tab(title="Existing Collections")
          div.cart__search
            TextInput(v-model="filterCollections", prepend-icon="search", :spellcheck="false")
          div.cart__summary(:class="{'cart__summary--empty': !filterCollections && !filteredCollections.length || filterCollections && filteredCollections.length === 0}")
            span(v-show="!filterCollections && !filteredCollections.length") No existing collections.
            span(v-show="!filterCollections && filteredCollections.length") #[strong {{ filteredCollections.length }}] collections:
            span(v-show="filterCollections && filteredCollections.length > 1") #[strong {{ filteredCollections.length }}] collections matching #[strong {{ filterCollections}}]:
            span(v-show="filterCollections && filteredCollections.length === 1") #[strong {{ filteredCollections.length }}] collection matching #[strong {{ filterCollections}}]:
            span(v-show="filterCollections && filteredCollections.length === 0") No collections matching #[strong {{ filterCollections}}].
          div.cart__list.cart__list--collections
            a.cart-collection(v-for="collection in filteredCollections", @click="toggleCollection(collection)", :class="[`cart-collection--${collection.type}`, {'cart-collection--selected': isSelectedCollection(collection)}]")
              div.form-check.form-check-checkbox.pointer-events-none
                label.form-label
                  input.form-check-input(type="checkbox", :checked="isSelectedCollection(collection)")
                  div.form-check-custom-input: Icon(icon="check", prefix="fas")
                  div.form-check-content
                    div.cart-collection__collection
                      div.cart-collection__icon: Icon(icon="collection", :style="collection.type === 'partner' ? `color: ${brandColor};` : ''")
                      span.cart-collection__name(v-html="$options.filters.highlight(collection.name, filterCollections)")
                    div.cart-collection__folder(v-show="collection.folder")
                      div.cart-collection__icon: Icon(icon="folder", prefix="fas")
                      span.cart-collection__name(v-html="$options.filters.highlight(collection.folder, filterCollections)")

        Tab.tab--new-collection(title="New Collection")
          InputGroup(label="Name", name="name")
            TextInput(v-model="newCollection.name", prepend-icon="collection")
          InputGroup(label="Folder", name="folder_id")
            Select(v-model="newCollection.folder_id", :endpoint="`options/${type}/folders`", prepend-option="No Folder")

    div.cart__body.cart__body--checking-out(v-show="view === 'checking-out'")
      Icon(icon="spinner-third", prefix="fad", spin)
      p(v-if="checkoutCollections.length === 1") Adding #[strong {{ count }}] pieces of content to #[strong(v-text="checkoutCollections[0].name")].
      p(v-else) Adding #[strong {{ count }}] pieces of content to #[strong {{ checkoutCollections.length }}] collections.

    div.cart__body.cart__body--checkout-success(v-if="view === 'checkout-success'")
      Icon(icon="check")
      div(v-if="checkoutCollections.length === 1")
        p All done! Your collection has been updated and your cart cleared.
        p: Button(type="primary", @click="gotoCollection(checkoutCollections[0].id)") View Collection
      div(v-else)
        p All done! Your collections have been updated and your cart cleared.
      p.mt-6: a.cart__reset(@click="reset()") close

    div.cart__footer(:class="`cart__footer--${view}`", v-show="!isEmpty && view != 'checking-out' && view != 'checkout-success'")
      a.cart__footer__secondary.cart__footer__secondary--clear(@click="clearCart", v-show="view === 'cart'") #[Icon(icon="times")] Clear
      a.cart__footer__secondary(@click="changeView('cart')", v-show="view === 'checkout'") #[Icon(icon="chevron-left")] Back
      Button(type="primary", append-icon="long-arrow-right", :disabled="isEmpty", @click="changeView('checkout')", v-show="view === 'cart'") Add To Collection
      Button(type="primary", append-icon="long-arrow-right", :disabled="isEmpty", @click="checkout('new')", v-show="view === 'checkout' && checkoutTab === 'new'") Create New Collection
      Button(type="primary", append-icon="long-arrow-right", :disabled="isEmpty || checkoutCollections.length === 0", @click="checkout('existing')", v-show="view === 'checkout' && checkoutTab === 'existing'")
        span(v-if="checkoutCollections.length === 0") Select collection(s)
        span(v-else-if="checkoutCollections.length === 1") Add to collection
        span(v-else) Add to {{ checkoutCollections.length }} collections
</template>

<script>
import { get, orderBy } from "lodash-es"
import { Tabs, Tab } from "@syntax51/app-kit"
import { mapGetters } from "vuex"
import CartItem from "./CartItem"

export default {
  components: {
    CartItem,
    Tab,
    Tabs
  },
  data: () => ({
    checkoutCollections: [],
    filterCollections: "",
    view: "cart",
    checkoutTab: "existing",
    newCollection: {
      name: "",
      folder_id: null
    }
  }),
  computed: {
    ...mapGetters({
      collections: "collections/collections",
      items: "cart/items",
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    },
    count() {
      return (this.items && this.items.length) || 0
    },
    isEmpty() {
      return this.count === 0
    },
    filteredCollections() {
      let collections = orderBy(this.collections, "id", "desc")

      if (this.filterCollections) {
        collections = this.collections.filter(
          collection =>
            collection.name.toLowerCase().includes(this.filterCollections.trim().toLowerCase()) ||
            collection.folder?.toLowerCase().includes(this.filterCollections.trim().toLowerCase()) ||
            collection.internal_codes.includes(this.filterCollections.trim())
        )
      }

      return collections
    },
    type() {
      let type

      if (this.user.hasPermission("collections.shared_manage")) {
        type = "partner"
      } else if (this.user.hasPermission("collections.manage")) {
        type = "personal"
      }

      return type
    }
  },
  methods: {
    isSelectedCollection(collection) {
      return this.checkoutCollections.find(col => col.id === collection.id)
    },
    toggleCollection(collection) {
      let index = this.checkoutCollections.findIndex(col => col.id === collection.id)

      if (index !== -1) {
        this.checkoutCollections.splice(index, 1)
      } else {
        this.checkoutCollections.push(collection)
      }
    },
    async checkout(action) {
      this.changeView("checking-out")
      let data

      if (action === "existing") {
        data = {
          collection_ids: this.checkoutCollections.map(collection => collection.id)
        }
      } else {
        data = {
          collection_name: this.newCollection.name,
          folder_id: this.newCollection.folder_id
        }
      }

      let response = await this.$api.post("cart/checkout", { action, ...data })

      this.checkoutCollections = get(response, "data.success")
      this.clearCart()
      this.changeView("checkout-success")

      setTimeout(() => {
        this.reset()
      }, 5000)
    },
    changeView(view) {
      this.view = view
    },
    clearCart() {
      this.$store.dispatch("cart/clear")
    },
    collectionName(name) {
      if (this.filterCollections) {
        return this.$options.filters.highlight("greece")
      }

      return name
    },
    gotoCollection(id) {
      this.$router.push({ name: "collection", params: { id } })
      this.reset()
    },
    reset() {
      this.checkoutCollections = []
      this.newCollection.name = ""
      this.newCollection.folder_id = null

      this.$emit("close")
      this.changeView("cart")
    },
    onCheckoutTab(tab) {
      let tabs = { 0: "existing", 1: "new" }
      this.checkoutTab = tabs[tab]
    }
  }
}
</script>

<style>
.cart {
  @apply absolute right-0 z-10 overflow-hidden rounded border bg-white shadow;
  top: 51px;
  width: 350px;

  &__empty {
    @apply p-8;
  }

  .highlight {
    @apply bg-primary-100;
  }

  &__search {
    @apply px-8 pt-3;
  }

  &__summary {
    @apply border-b py-6 px-8;

    &--empty {
      @apply border-none;
    }
  }

  &__list {
    @apply overflow-x-hidden;
    max-height: 400px;

    &__item {
      @apply flex px-8 py-5;

      & + & {
        @apply border-t;
      }
    }

    &__empty {
      @apply flex px-8 py-5;
    }

    &--collections {
      .cart__list__item:hover {
        @apply bg-primary-50;
      }
    }
  }

  .cart-collection {
    @apply block px-8 py-5;

    &:hover {
      @apply bg-primary-50;
    }

    &--selected {
      @apply bg-primary-50;

      &:hover {
        @apply bg-primary-100;
      }
    }

    & + .cart-collection {
      @apply border-t;
    }

    &__collection,
    &__folder {
      @apply flex;

      .icon {
        @apply text-supplement;
      }
    }

    &__collection {
      @apply font-medium;
    }

    &__folder {
      @apply mt-1 text-sm text-supplement;
    }

    &__icon {
      @apply mr-3 w-4 flex-shrink-0 text-primary-default;
    }
  }

  &__count {
    @apply px-8 pt-5;
  }

  &__intro-text {
    .button.primary {
      @apply mx-1 rounded;
      height: 26px;
      line-height: 26px;
    }

    .button--icon-only .button-prepend {
      @apply px-3;
    }
  }

  &__intro-image {
    @apply mt-6 border;
  }

  &__body {
    &--checkout {
      .tabs-navigation {
        @apply border-b-0;

        &-item {
          @apply w-1/2 border-b-0 border-t-2 bg-gray-100 p-4 text-center font-semibold transition-none;

          &.is-active {
            @apply border-primary-default bg-white;
          }
        }
      }
    }

    &--checking-out,
    &--checkout-success {
      @apply p-20 text-center;

      .icon {
        @apply mb-6 text-6xl text-primary-default;
      }
    }

    &--checking-out .icon {
      animation: fa-spin 1.25s infinite linear;
    }

    &--checkout-success .icon {
      @apply text-green-500;
    }
  }

  &__reset {
    @apply text-supplement;
  }

  &__footer {
    @apply flex justify-between bg-gray-100 p-4;
    min-height: 62px;

    &__secondary {
      @apply mx-4 flex items-center font-medium text-supplement;

      .icon {
        @apply mr-2;
      }

      &--clear:hover {
        @apply text-red-500;
      }
    }
  }

  .tab--new-collection {
    @apply px-8;
  }
}
</style>
