<template lang="pug">
  div.logo
    img.content__header-logo(src="~@/assets/images/logo.png", :alt="appName", width="180")
</template>

<script>
import { appName } from "@/config"

export default {
  data: () => ({
    appName
  })
}
</script>
