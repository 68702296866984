<template lang="pug">
  header.mobile-header
    div.mobile-header-inner
      div.mobile-header-main
        Button.mobile-header-toggle(prepend-icon="bars", icon-prefix="fas", @click="openMobileSidebar()")
        Logo.mobile-header-brand
      div(v-if="user.hasPermission('content.private_manage')")
        Button.mobile-header-add-to-collection(type="primary", @click="toggleCart", prepend-icon="shopping-bag") {{ cartCount }}
        Cart(ref="cart", @close="cartOpen = false", v-show="cartOpen")
</template>

<script>
import HeaderMixin from "./HeaderMixin"

export default {
  mixins: [HeaderMixin],
  methods: {
    openMobileSidebar() {
      document.body.classList.add("mobile-sidebar-open")
    }
  }
}
</script>

<style>
.mobile-header {
  @apply relative;
  @apply lg:hidden;
  height: theme("header.height");

  &-inner {
    @apply fixed left-0 right-0 top-0 z-50 flex items-center justify-between bg-white px-4 shadow;
    height: theme("header.height");
  }

  &-main {
    @apply flex items-center justify-between;
  }

  &-toggle {
    @apply mr-2 border-none;
  }

  &-add-to-collection.button {
    @apply border-secondary-default bg-secondary-default;

    &:hover {
      @apply border-secondary-hover bg-secondary-hover;
    }
  }

  .cart {
    top: theme("header.height");
  }
}
</style>
