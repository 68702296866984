import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    collections: []
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loading = status
    },
    SET_COLLECTIONS(state, collections) {
      state.collections = collections
    },
    ADD_COLLECTION(state, collection) {
      state.collections.push(collection)
    },
    REMOVE_COLLECTION(state, id) {
      state.collections = state.collections.filter(collection => collection.id !== id)
    }
  },
  actions: {
    async fetchCollections({ commit }) {
      commit("SET_LOADING_STATUS", true)
      let collections = await Vue.prototype.$api.values("options/all/collections")
      commit("SET_COLLECTIONS", collections)
      commit("SET_LOADING_STATUS", false)
    },
    async add({ commit }, id) {
      commit("SET_LOADING_STATUS", true)
      // @todo
      console.log(id)
      commit("SET_LOADING_STATUS", false)
    },
    async remove({ commit }, id) {
      commit("SET_LOADING_STATUS", true)
      // @todo
      console.log(id)
      commit("SET_LOADING_STATUS", false)
    }
  },
  getters: {
    collections: state => state.collections,
    count: state => (state.collections && state.collections.length) || 0,
    find: state => id => state.collections.find(collection => collection.id == id)
  }
}
